import { all } from "redux-saga/effects";
import getBudgetSaga from "sagas/budgetSaga";
import getChecklistSata from "sagas/checklistSaga";
import getEventSaga from "sagas/eventSaga";
import getNoteSaga from "sagas/noteSaga";
import getTripSaga from "sagas/tripSaga";
import getUserSaga from "sagas/userSaga";

export default function* rootSaga() {
  yield all([getTripSaga(), getEventSaga(), getUserSaga(), getChecklistSata(), getBudgetSaga(), getNoteSaga()]);
}
