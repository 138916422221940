import moment from "moment";
import moment_timezone from "moment-timezone"; // eslint-disable-line no-unused-vars

const eventReducer = (
  state = {
    events: {},
    loading: false,
    isSaving: false,
    selectedEvent: null,
  },
  action
) => {
  switch (action.type) {
    case "EVENT_FETCH_REQUESTED":
    case "EVENTS_FETCH_REQUESTED":
      return {
        ...state,
        events: {
          ...state.events,
        },
        loading: true,
      };
    case "EVENT_VOTE_REQUESTED":
      return { ...state, voting: true };
    case "EVENTS_FETCH_SUCCESSFUL":
      const events = {};
      for (const event of action.response.page) {
        event.start = moment.unix(event.start).toDate();
        event.end = moment.unix(event.end).toDate();
        events[event.id] = event;
      }
      return {
        ...state,
        events: {
          ...state.events,
          [action.tripId]: { ...state.events[action.tripId], ...events },
        },
        loading: false,
      };
    case "EVENT_FETCH_SUCCESSFUL":
    case "EVENT_VOTE_SUCCESSFUL":
    case "EVENT_CREATE_SUCCESSFUL":
    case "EVENT_UPDATE_SUCCESSFUL":
      action.response.start = moment.unix(action.response.start).toDate();
      action.response.end = moment.unix(action.response.end).toDate();
      state.events[action.tripId][action.response.id] = action.response;
      return {
        ...state,
        events: state.events,
        selectedEvent: action.response,
        voting: false,
        loading: false,
        isSaving: false,
      };
    case "EVENT_CACHE_SUCCESSFUL":
      action.response.start = moment.unix(action.response.start).toDate();
      action.response.end = moment.unix(action.response.end).toDate();
      state.events[action.tripId][action.response.id] = action.response;
      return {
        ...state,
        events: state.events,
        selectedEvent: action.response,
      };
    case "EVENT_SELECTED_SUCCESSFUL":
      return { ...state, selectedEvent: action.event };
    case "EVENT_DELETE_SUCCESSFUL":
      delete state.events[action.tripId][action.id];
      return {
        ...state,
        events: {
          ...state.events,
        },
        voting: false,
        loading: false,
        isSaving: false,
      };
    case "EVENT_DELETE_FAILED":
    case "EVENT_VOTE_FAILED":
    case "EVENT_CREATE_FAILED":
    case "EVENT_UPDATE_FAILED":
    case "EVENTS_FETCH_FAILED":
      return { ...state, loading: false, isSaving: false };
    case "BUDGET_UPDATE_SUCCESSFUL":
      console.log("BUDGET_UPDATE_SUCCESSFUL IN EVENT REDUCER", action);
      return {
        ...state,
        events: {
          ...state.events,
          [action.tripId]: {
            ...state.events[action.tripId],
            [action.eventId]: {
              ...state.events[action.tripId][action.eventId],
              budget: Object.keys(action.response).map((key) => action.response[key]),
            },
          },
        },
        loading: false,
      };
    default:
      return state;
  }
};

export default eventReducer;
