import { SegmentWrapper } from "api/segment";
import { COGNITO, ENV } from "constants/items";

// import Amplify from "aws-amplify";
import Amplify from "@aws-amplify/core";
import Knock from "@knocklabs/client";
import * as Sentry from "@sentry/react";

Amplify.configure({
  Auth: COGNITO,
});

const userReducer = (
  state = {
    user: null,
    loading: false,
    isSaving: false,
  },
  action
) => {
  switch (action.type) {
    case "USER_LOGOUT":
      return { ...state, error: null, user: null, loading: false };
    case "USER_HYDRATE_REQUESTED":
      return { ...state, loading: true };
    case "USER_UPDATE_SUCCESSFUL":
    case "USER_HYDRATE_SUCCESSFUL":
      SegmentWrapper.identify(action.response.user.id, {
        name: action.response.user.name,
        email: action.response.user.email,
      });

      const knockClient = new Knock(process.env.REACT_APP_KNOCK_PUBLIC_API_KEY);
      knockClient.authenticate(
        // The id of the user you want to authenticate against
        action.response.user.id,
        // // You only need this in production environments
        action.response.user.knock
      );

      Sentry.setUser({
        id: action.response.user.id,
        email: action.response.user.email,
        ip_address: "{{auto}}",
      });

      return {
        ...state,
        user: action.response.user,
        avatar: `${ENV.REACT_APP_CDN_URL}/avatars/${action.response.user.id}?t=${Date.now()}`,
        loading: false,
        isSaving: false,
      };
    case "USER_UPDATE_REQUESTED":
      return { ...state, loading: true, isSaving: true };
    case "USER_UPDATE_FAILED":
      return { ...state, loading: false, isSaving: false };
    case "USER_HYDRATE_FAILED":
      return { ...state, user: null, loading: false };
    default:
      return state;
  }
};

export default userReducer;
