import EventsApi from "api/services/events";
import moment from "moment";
import moment_timezone from "moment-timezone"; // eslint-disable-line no-unused-vars
import { put, takeLatest } from "redux-saga/effects";

import { createStandaloneToast } from "@chakra-ui/react";
import * as Sentry from "@sentry/react";

const api = new EventsApi();
const { toast } = createStandaloneToast();

function* fetchEvents(action) {
  try {
    const start = action.payload.start || 0;
    let page = action.payload.page || 1;
    let total_pages = 1;
    let pub = action.payload.pub || false;

    while (page <= total_pages) {
      const json = yield api.getEvents(action.payload.tripId, page, start, pub);
      yield put({ type: "EVENTS_FETCH_SUCCESSFUL", response: json, tripId: action.payload.tripId });
      page++;
      total_pages = json.pagination.count;
      if (page > 100) break; //fail safe, "hard limit of 2500 events"
    }
  } catch (e) {
    Sentry.captureException(e);
    yield put({ type: "EVENTS_FETCH_FAILED", message: e.message });
  }
}

function* fetchEvent(action) {
  try {
    let pub = action.payload.pub || false;
    const json = yield api.getEvent(action.payload.id, pub);
    yield put({ type: "EVENT_FETCH_SUCCESSFUL", response: json, id: action.payload.id, tripId: action.payload.tripId });
  } catch (e) {
    Sentry.captureException(e);
    yield put({ type: "EVENT_FETCH_FAILED", message: e.message });
  }
}

function* submitEventVote(action) {
  try {
    const json = yield api.voteEvent(action.payload.id);
    yield put({ type: "EVENT_VOTE_SUCCESSFUL", response: json, tripId: action.payload.tripId, id: action.payload.id });
    if (action.meta && action.meta.callback) {
      action.meta.callback();
    }
  } catch (e) {
    Sentry.captureException(e);
    yield put({ type: "EVENT_VOTE_FAILED", message: e.message });
  }
}

function* updateEvent(action) {
  try {
    let start = moment(action.payload.event.start);
    let end = moment(action.payload.event.end);

    // If it's allDay, then set the hour/minute to 12pm-1pm so it doesn't shift in timezones :eyeroll:
    if (action.payload.event.allDay) {
      start.set("hour", 12).set("minute", 0);
      end.set("hour", 13).set("minute", 0);
    }

    action.payload.event.start = start.unix();
    action.payload.event.end = end.unix();

    const json = yield api.updateEvent(action.payload.id, action.payload.event);
    toast({
      title: "Event saved.",
      description: "We've saved your event",
      status: "success",
      duration: 2000,
      isClosable: true,
    });
    yield put({ type: "EVENT_UPDATE_SUCCESSFUL", response: json, tripId: action.payload.tripId, created: action.payload.created, id: action.payload.id });
    if (action.meta && action.meta.callback) {
      action.meta.callback(json);
    }
  } catch (e) {
    Sentry.captureException(e);
    toast({
      title: "Error.",
      description: "There was an error saving this event",
      status: "error",
      duration: 2000,
      isClosable: true,
    });
    yield put({ type: "EVENT_UPDATE_FAILED", message: e.message });
  }
}

function* cacheEvent(action) {
  try {
    let start = moment(action.payload.event.start);
    let end = moment(action.payload.event.end);

    // If it's allDay, then set the hour/minute to 12pm-1pm so it doesn't shift in timezones :eyeroll:
    if (action.payload.event.allDay) {
      start.set("hour", 12).set("minute", 0);
      end.set("hour", 13).set("minute", 0);
    }

    action.payload.event.start = start.unix();
    action.payload.event.end = end.unix();

    yield put({ type: "EVENT_CACHE_SUCCESSFUL", response: action.payload.event, tripId: action.payload.tripId, created: action.payload.created, id: action.payload.id });
    if (action.meta && action.meta.callback) {
      action.meta.callback(action.payload.event);
    }
  } catch (e) {
    Sentry.captureException(e);
    yield put({ type: "EVENT_CACHE_FAILED", message: e.message });
  }
}

function* createEvent(action) {
  try {
    let start = moment(action.payload.event.start);
    let end = moment(action.payload.event.end);

    // If it's allDay, then set the hour/minute to 12pm-1pm so it doesn't shift in timezones :eyeroll:
    if (action.payload.event.allDay) {
      start.set("hour", 12).set("minute", 0);
      end.set("hour", 13).set("minute", 0);
    }

    action.payload.event.start = start.unix();
    action.payload.event.end = end.unix();

    const json = yield api.createEvent(action.payload.event);
    toast({
      title: "Event saved.",
      description: "We've saved your event",
      status: "success",
      duration: 2000,
      isClosable: true,
    });
    yield put({ type: "EVENT_CREATE_SUCCESSFUL", response: json, tripId: action.payload.tripId, id: action.payload.id });
    if (action.meta && action.meta.callback) {
      action.meta.callback(json);
    }
  } catch (e) {
    toast({
      title: "Error.",
      description: "There was an error saving this event",
      status: "error",
      duration: 2000,
      isClosable: true,
    });
    yield put({ type: "EVENT_CREATE_FAILED", message: e.message });
  }
}

function* selectEvent(action) {
  try {
    yield put({ type: "EVENT_SELECTED_SUCCESSFUL", id: action.payload.id, event: action.payload.event });
    if (action.meta && action.meta.callback) {
      action.meta.callback();
    }
  } catch (e) {
    yield put({ type: "EVENT_SELECTED_FAILED", message: e.message });
  }
}

function* deleteEvent(action) {
  try {
    yield api.deleteEvent(action.payload.id, action.payload.cancel_reason);
    toast({
      title: "Event removed.",
      description: "We've removed the event from your trip",
      status: "success",
      duration: 2000,
      isClosable: true,
    });
    yield put({ type: "EVENT_DELETE_SUCCESSFUL", tripId: action.payload.tripId, id: action.payload.id });
    if (action.meta && action.meta.callback) {
      action.meta.callback();
    }
  } catch (e) {
    toast({
      title: "Error.",
      description: "Error deleting this event. Please try again later",
      status: "error",
      duration: 2000,
      isClosable: true,
    });
    yield put({ type: "EVENT_DELETE_FAILED", message: e.message });
  }
}

function* mySaga() {
  yield takeLatest("EVENTS_FETCH_REQUESTED", fetchEvents);
  yield takeLatest("EVENT_FETCH_REQUESTED", fetchEvent);
  yield takeLatest("EVENT_VOTE_REQUESTED", submitEventVote);
  yield takeLatest("EVENT_UPDATE_REQUESTED", updateEvent);
  yield takeLatest("EVENT_CACHE_REQUESTED", cacheEvent);
  yield takeLatest("EVENT_CREATE_REQUESTED", createEvent);
  yield takeLatest("EVENT_DELETE_REQUESTED", deleteEvent);
  yield takeLatest("EVENT_SELECTED_REQUESTED", selectEvent);
}

export default mySaga;
