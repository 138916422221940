import { ENV } from "constants/items";

export const SegmentWrapper = () => {};

SegmentWrapper.track = (...args) => {
  console.log("Segment::track", ...args);
  if (ENV.REACT_APP_ENV === "production") window.analytics.track(...args);
};

SegmentWrapper.page = (...args) => {
  console.log("Segment::page", ...args);
  if (ENV.REACT_APP_ENV === "production") window.analytics.page(...args);
};

SegmentWrapper.identify = (...args) => {
  console.log("Segment::identify", ...args);
  if (ENV.REACT_APP_ENV === "production") window.analytics.identify(...args);
};
