import BudgetApi from "api/services/budget";
import { put, takeLatest } from "redux-saga/effects";

import { createStandaloneToast } from "@chakra-ui/react";
import * as Sentry from "@sentry/react";

const api = new BudgetApi();
const { toast } = createStandaloneToast();

function* fetchBudget(action) {
  try {
    const json = yield api.getBudget(action.payload.tripId);
    yield put({ type: "BUDGET_FETCH_SUCCESSFUL", response: json, tripId: action.payload.tripId });
    if (action.meta && action.meta.callback) {
      action.meta.callback(json);
    }
  } catch (e) {
    Sentry.captureException(e);
    yield put({ type: "BUDGET_FETCH_FAILED", message: e.message });
  }
}

function* updateBudget(action) {
  try {
    const json = yield api.updateBudget(action.payload.eventId, action.payload.lineItems);
    yield put({ type: "BUDGET_UPDATE_SUCCESSFUL", response: json, tripId: action.payload.tripId, eventId: action.payload.eventId });
    toast({
      title: "Budget saved.",
      description: "We've saved your budget updates",
      status: "success",
      duration: 2000,
      isClosable: true,
    });
    if (action.meta && action.meta.callback) {
      action.meta.callback(json);
    }
  } catch (e) {
    Sentry.captureException(e);
    toast({
      title: "Error.",
      description: "There was an error saving this budget update",
      status: "error",
      duration: 2000,
      isClosable: true,
    });
    yield put({ type: "BUDGET_UPDATE_FAILED", message: e.message });
  }
}

function* mySaga() {
  yield takeLatest("BUDGET_FETCH_REQUESTED", fetchBudget);
  yield takeLatest("BUDGET_UPDATE_REQUESTED", updateBudget);
}

export default mySaga;
