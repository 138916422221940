const budgetReducer = (
  state = {
    loading: false,
  },
  action
) => {
  switch (action.type) {
    case "BUDGET_UPDATE_REQUESTED":
    case "BUDGET_FETCH_REQUESTED":
      return { ...state, loading: true };
    case "BUDGET_UPDATE_SUCCESSFUL":
      return {
        ...state,
        [action.tripId]: {
          ...state[action.tripId],
          events: {
            ...state[action.tripId].events,
            [action.eventId]: {
              ...state[action.tripId].events[action.eventId],
              line_items: action.response,
            },
          },
        },
        loading: false,
      };
    case "BUDGET_FETCH_SUCCESSFUL":
      return {
        ...state,
        [action.tripId]: {
          events: action.response.events,
          totals: action.response.totals,
        },
        loading: false,
      };
    case "EVENT_UPDATE_SUCCESSFUL":
      try {
        return {
          ...state,
          [action.tripId]: {
            ...state[action.tripId],
            events: {
              ...state[action.tripId].events,
              [action.id]: {
                ...state[action.tripId].events[action.id],
                line_items: action.response.budget,
              },
            },
          },
          loading: false,
        };
      } catch (error) {
        return { ...state, loading: false };
      }
    default:
      return state;
  }
};

export default budgetReducer;
