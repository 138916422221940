import moment from "moment";
import moment_timezone from "moment-timezone"; // eslint-disable-line no-unused-vars

const noteReducer = (
  state = {
    notes: {},
    loading: false,
    isSaving: false,
  },
  action
) => {
  switch (action.type) {
    case "NOTE_FETCH_REQUESTED":
    case "NOTES_FETCH_REQUESTED":
      return {
        ...state,
        notes: {
          ...state.notes,
        },
        loading: true,
      };
    case "NOTES_FETCH_SUCCESSFUL":
      const notes = {};
      for (const note of action.response.page) {
        notes[note.id] = note;
      }
      return {
        ...state,
        notes: {
          ...state.notes,
          [action.tripId]: { ...state.notes[action.tripId], ...notes },
        },
        loading: false,
      };
    case "NOTE_FETCH_SUCCESSFUL":
      action.response.start = moment.unix(action.response.start).toDate();
      action.response.end = moment.unix(action.response.end).toDate();
      state.notes[action.tripId][action.response.id] = action.response;
      return {
        ...state,
        notes: state.notes,
        loading: false,
        isSaving: false,
      };
    case "NOTES_FETCH_FAILED":
    case "NOTE_FETCH_FAILED":
      return { ...state, loading: false, isSaving: false };
    default:
      return state;
  }
};

export default noteReducer;
