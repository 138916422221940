const checklistReducer = (
  state = {
    checklists: {},
    isSaving: false,
  },
  action
) => {
  switch (action.type) {
    case "CHECKLISTS_FETCH_REQUESTED":
    case "CHECKLIST_FETCH_REQUESTED":
      return { ...state, loading: true };
    case "CHECKLIST_UPDATE_REQUESTED":
      return { ...state, loading: true, isSaving: true };
    case "CHECKLISTS_FETCH_SUCCESSFUL":
      let list = {};
      for (let checklist of action.response.page) {
        list[checklist.id] = checklist;
      }
      return {
        ...state,
        checklists: {
          ...state.checklists,
          [action.tripId]: list,
        },
        loading: false,
      };
    case "CHECKLIST_UPDATE_SUCCESSFUL":
    case "CHECKLIST_FETCH_SUCCESSFUL":
      return {
        ...state,
        checklists: {
          ...state.checklists,
          [action.tripId]: {
            ...state.checklists[action.tripId],
            [action.response.id]: action.response,
          },
        },
        loading: false,
        isSaving: false,
      };
    case "CHECKLIST_UPDATE_FAILURE":
      return { ...state, isSaving: false };
    default:
      return state;
  }
};

export default checklistReducer;
