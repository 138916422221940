import Api from "api/api";

export default class NotesApi extends Api {
  constructor() {
    super();
  }
  async getNotes(tripId, page = 1, start = 0) {
    return this.axios.get(`/notes/?tripId=${tripId}&page=${page}&start=${start}`).then((response) => {
      return response.data;
    });
  }

  async getNote(noteId) {
    return this.axios.get(`/notes/${noteId}`).then((response) => {
      return response.data;
    });
  }

  async createNote(note) {
    return this.axios.post(`/notes/`, note).then((response) => {
      return response.data;
    });
  }

  async updateNote(noteId, note) {
    return this.axios.put(`/notes/${noteId}`, note).then((response) => {
      return response.data;
    });
  }

  async deleteNote(noteId) {
    return this.axios.delete(`/notes/${noteId}`).then((response) => {
      return response.data;
    });
  }
}
