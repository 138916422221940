import Api from "api/api";

export default class EventsApi extends Api {
  constructor() {
    super();
  }
  async getEvents(tripId, page = 1, start = 0, pub = false) {
    return this.axios.get(`/events/${pub ? "public" : ""}?tripId=${tripId}&page=${page}&start=${start}`).then((response) => {
      return response.data;
    });
  }

  async getEvent(eventId, pub = false) {
    return this.axios.get(`/events/${pub ? "public/" : ""}${eventId}`).then((response) => {
      return response.data;
    });
  }

  async createEvent(event) {
    return this.axios.post("/events/", event).then((response) => {
      return response.data;
    });
  }

  async updateEvent(eventId, event) {
    return this.axios.put(`/events/${eventId}`, event).then((response) => {
      return response.data;
    });
  }

  async deleteEvent(eventId, cancel_reason) {
    return this.axios.delete(`/events/${eventId}`, { cancel_reason }).then((response) => {
      return response.data;
    });
  }

  async voteEvent(eventId) {
    return this.axios.post(`/events/${eventId}/vote`, {}).then((response) => {
      return response.data;
    });
  }
}
