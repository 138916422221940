import { BsFlag } from "@react-icons/all-files/bs/BsFlag";
import { GrCircleQuestion } from "@react-icons/all-files/gr/GrCircleQuestion";
import { IoMdAirplane } from "@react-icons/all-files/io/IoMdAirplane";
import { IoMdBoat } from "@react-icons/all-files/io/IoMdBoat";
import { IoMdCalendar } from "@react-icons/all-files/io/IoMdCalendar";
import { IoMdCar } from "@react-icons/all-files/io/IoMdCar";
import { IoMdCheckboxOutline } from "@react-icons/all-files/io/IoMdCheckboxOutline";
import { IoMdHome } from "@react-icons/all-files/io/IoMdHome";
import { IoMdRestaurant } from "@react-icons/all-files/io/IoMdRestaurant";
import { IoMdRibbon } from "@react-icons/all-files/io/IoMdRibbon";
import { IoMdTrain } from "@react-icons/all-files/io/IoMdTrain";
import { IoMdWalk } from "@react-icons/all-files/io/IoMdWalk";

export const TYPES = {
  TRAVEL_FLIGHT: "flight",
  TRAVEL_CAR: "car",
  TRAVEL_BOAT: "boat",
  TRAVEL_TRAIN: "train",
  TRAVEL_WALK: "walk",
  ACCOMMODATIONS: "accommodations",
  EVENT: "event",
  ATTRACTION: "attraction", //IoMdGlobe
  FOOD: "food", //IoMdRestaurant
  TASK: "task", //IoMdCheckboxOutline
  EXPERIENCE: "experience",
};

export const ICONS = {
  [TYPES.ACCOMMODATIONS]: { icon: IoMdHome, name: "Accommodation" },
  [TYPES.EVENT]: { icon: IoMdCalendar, name: "Event" },
  [TYPES.TRAVEL_FLIGHT]: { icon: IoMdAirplane, name: "Flight" },
  [TYPES.TRAVEL_CAR]: { icon: IoMdCar, name: "Car" },
  [TYPES.TRAVEL_BOAT]: { icon: IoMdBoat, name: "Boat" },
  [TYPES.TRAVEL_TRAIN]: { icon: IoMdTrain, name: "Train/Public Transit" },
  [TYPES.TRAVEL_WALK]: { icon: IoMdWalk, name: "Walk" },
  [TYPES.ATTRACTION]: { icon: IoMdRibbon, name: "Attraction" },
  [TYPES.FOOD]: { icon: IoMdRestaurant, name: "Food/Resturant" },
  [TYPES.TASK]: { icon: IoMdCheckboxOutline, name: "Task" },
  [TYPES.EXPERIENCE]: { icon: BsFlag, name: "Experience" },
  unknown: { icon: GrCircleQuestion, name: "Uncategorized" },
};

export const THEMES = {
  pink: {
    background: "#FED7E2", //100
    border: "#F687B3", //300
    text: "#D53F8C",
  },
  purple: {
    background: "#E9D8FD",
    border: "#B794F4",
    text: "#805AD5",
  },
  cyan: {
    background: "#C4F1F9", //100
    border: "#76E4F7", //300
    text: "#00B5D8",
  },
  blue: {
    background: "#BEE3F8", //blue.100
    border: "#63B3ED", //blue.300
    text: "#3182CE",
  },
  teal: {
    background: "#E6FFFA", //100
    border: "#2C7A7B", //300
    text: "#319795", //500
  },
  green: {
    background: "#C6F6D5", //100
    border: "#68D391", //300
    text: "#38A169",
  },
  yellow: {
    background: "#FEFCBF", //100
    border: "#F6E05E", //300
    text: "#D69E2E",
  },
  orange: {
    background: "#FEEBC8", //100
    border: "#F6AD55", //300
    text: "#DD6B20",
  },
  red: {
    background: "#FED7D7", //100
    border: "#FC8181", //300
    text: "#E53E3E",
  },
  gray: {
    background: "#EDF2F7", //100
    border: "#CBD5E0", //300
    text: "#718096", //500
  },
};

export const COGNITO = {
  region: process.env.REACT_APP_COGNITO_REGION,
  userPoolId: process.env.REACT_APP_COGNITO_USERPOOLID,
  userPoolWebClientId: process.env.REACT_APP_COGNITO_USERPOOLWEBCLIENTID,
};

export const ENV = process.env;

export const PRO_OPTIONS = [
  { id: 1, desc: "Unlimated trip events" },
  { id: 2, desc: "Sync your trip to your favorite calendar" },
  { id: 3, desc: "Robust budgeting tools (multiple costs, export and more!)" },
  { id: 4, desc: "Unlimited checklists" },
  { id: 5, desc: "Unlimited file attachments" },
];
export const PRO_COST = "$4.99";
