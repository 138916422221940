import ChecklistsApi from "api/services/checklists";
import { put, takeLatest } from "redux-saga/effects";

import { createStandaloneToast } from "@chakra-ui/react";
import * as Sentry from "@sentry/react";

const api = new ChecklistsApi();
const { toast } = createStandaloneToast();

function* fetchChecklists(action) {
  try {
    const json = yield api.getChecklists(action.payload.tripId);
    yield put({ type: "CHECKLISTS_FETCH_SUCCESSFUL", response: json, tripId: action.payload.tripId });
  } catch (e) {
    Sentry.captureException(e);
    yield put({ type: "CHECKLISTS_FETCH_FAILED", message: e.message });
  }
}

function* fetchChecklist(action) {
  try {
    const json = yield api.getChecklist(action.payload.checklistId);
    yield put({ type: "CHECKLIST_FETCH_SUCCESSFUL", response: json, tripId: action.payload.tripId, checklistId: action.payload.checklistId });
  } catch (e) {
    Sentry.captureException(e);
    yield put({ type: "CHECKLIST_FETCH_FAILED", message: e.message });
  }
}

function* updateChecklist(action) {
  try {
    const json = yield api.updateChecklist(action.payload.tripId, action.payload.checklistId, action.payload.items, action.payload.title, action.payload.description);
    toast({
      title: "Success.",
      description: "Checklist updated",
      status: "success",
      duration: 2000,
      isClosable: true,
    });
    yield put({ type: "CHECKLIST_UPDATE_SUCCESSFUL", response: json, tripId: action.payload.tripId, checklistId: action.payload.checklistId });
  } catch (e) {
    Sentry.captureException(e);
    toast({
      title: "Error.",
      description: "There was an error updating list",
      status: "error",
      duration: 2000,
      isClosable: true,
    });
    yield put({ type: "CHECKLIST_UPDATE_FAILED", message: e.message, tripId: action.payload.tripId, checklistId: action.payload.checklistId });
  }
}

function* mySaga() {
  yield takeLatest("CHECKLISTS_FETCH_REQUESTED", fetchChecklists);
  yield takeLatest("CHECKLIST_FETCH_REQUESTED", fetchChecklist);
  yield takeLatest("CHECKLIST_UPDATE_REQUESTED", updateChecklist);
}

export default mySaga;
