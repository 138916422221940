import Api from "api/api";

export default class BudgetApi extends Api {
  constructor() {
    super();
  }

  async getBudget(tripId) {
    return this.axios.get(`/budget/?tripId=${tripId}`).then((response) => {
      return response.data;
    });
  }

  async updateBudget(eventId, lineItems) {
    return this.axios
      .put(`/budget/${eventId}`, {
        items: lineItems,
      })
      .then((response) => {
        return response.data;
      });
  }
}
