import "css/App.css";

import { lazy, Suspense, useEffect } from "react";

import { SegmentWrapper } from "api/segment";
import { Route, Link as RouterLink, Routes } from "react-router-dom";

import { Box, Button, Heading, Spinner, Text } from "@chakra-ui/react";

const TripWrapper = lazy(() => import("pages/trips/trip"));
const AdventuresIndex = lazy(() => import("pages/trips"));
const Landing = lazy(() => import("pages/landing"));
const AmplifyPage = lazy(() => import("pages/login"));
const CreateTripPage = lazy(() => import("pages/trips/create"));
const TripListPage = lazy(() => import("pages/trips/listpage"));

const CalendarPage = lazy(() => import("pages/trips/trip/calendar"));
const BudgetPage = lazy(() => import("pages/trips/trip/budget"));
const SettingsPage = lazy(() => import("pages/trips/trip/settings"));
const ChecklistIndexPage = lazy(() => import("pages/trips/trip/checklist"));
const ChecklistListPage = lazy(() => import("pages/trips/trip/checklist/list"));
const MapPage = lazy(() => import("pages/trips/trip/map"));
const PaymentPage = lazy(() => import("pages/trips/trip/payment"));
const TourPage = lazy(() => import("pages/trips/trip/tour"));
const TourDetailsPage = lazy(() => import("pages/trips/trip/tour/detail"));
// const TourBookingPage = lazy(() => import("pages/trips/trip/tour/booking"));
// const NotesPage = lazy(() => import("pages/trips/trip/note"));
// const NotePage = lazy(() => import("pages/trips/trip/note/note"));
const CachedExperiences = lazy(() => import("pages/landing/experiences"));
// const TravelAgent = lazy(() => import("pages/landing/travelagent"));
const ContactPage = lazy(() => import("pages/landing/contact"));
const SharePage = lazy(() => import("pages/trips/trip/share/share"));

const ProfilePage = lazy(() => import("pages/profile/index"));

export const Page = ({ category, name, children, properties = {} }) => {
  useEffect(() => {
    SegmentWrapper.page(category, {
      ...properties,
      pagename: name,
    });
  }, [name]);
  return children;
};

function App() {
  return (
    <div className="App">
      <Suspense
        fallback={
          <Box p={3}>
            <Spinner />
          </Box>
        }>
        <Routes>
          <Route
            index
            element={
              <Page category="marketing" name="/">
                <Landing />
              </Page>
            }
            name="/"
          />
          <Route
            path="/experiences"
            element={
              <Page category="marketing" name="/experiences">
                <CachedExperiences />
              </Page>
            }
          />
          <Route
            path="/contact"
            element={
              <Page category="marketing" name="/contact">
                <ContactPage />
              </Page>
            }
          />

          <Route
            path="*"
            element={
              <Page category="application" name="/404">
                <NotFound />
              </Page>
            }
          />
        </Routes>
      </Suspense>
    </div>
  );
}

function NotFound() {
  return (
    <Box textAlign="center" py={10} px={6}>
      <Heading as="h1" size="4xl" color="teal.500">
        Oh Dear...
      </Heading>
      <Text my={6}>404: The page you're looking for does not seem to exist</Text>
      <Button as={RouterLink} to="/" colorScheme="teal" size="lg">
        Go to Home
      </Button>
    </Box>
  );
}

export default App;
