import NotesApi from "api/services/notes";
import { put, takeLatest } from "redux-saga/effects";

import * as Sentry from "@sentry/react";

const api = new NotesApi();

function* fetchNotes(action) {
  try {
    const start = action.payload.start || 0;
    let page = action.payload.page || 1;
    let total_pages = 1;

    while (page <= total_pages) {
      const json = yield api.getNotes(action.payload.tripId, page, start);
      yield put({ type: "NOTES_FETCH_SUCCESSFUL", response: json, tripId: action.payload.tripId });
      page++;
      total_pages = json.pagination.count;
      if (page > 100) break; //fail safe, "hard limit of 2500 events"
    }
  } catch (e) {
    Sentry.captureException(e);
    yield put({ type: "NOTES_FETCH_FAILED", message: e.message });
  }
}

function* fetchNote(action) {
  try {
    const json = yield api.getNote(action.payload.id);
    yield put({ type: "NOTE_FETCH_SUCCESSFUL", response: json, id: action.payload.id, tripId: action.payload.tripId });
  } catch (e) {
    Sentry.captureException(e);
    yield put({ type: "NOTE_FETCH_FAILED", message: e.message });
  }
}

function* mySaga() {
  yield takeLatest("NOTES_FETCH_REQUESTED", fetchNotes);
  yield takeLatest("NOTE_FETCH_REQUESTED", fetchNote);
}

export default mySaga;
