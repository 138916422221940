import moment from "moment";
import moment_timezone from "moment-timezone"; // eslint-disable-line no-unused-vars

const tripReducer = (
  state = {
    trips: {},
    viewableTimezone: [],
    isSaving: false,
    isError: false,
  },
  action
) => {
  switch (action.type) {
    case "TRIPS_FETCH_REQUESTED":
    case "TRIP_FETCH_REQUESTED":
      return { ...state, loading: true, isError: false };
    case "TRIP_UPDATE_REQUESTED":
      return { ...state, loading: true, isSaving: true };
    case "TRIPS_FETCH_SUCCESSFUL":
      let trips = {};
      if (action.response?.page)
        for (let trip of action.response.page) {
          trip.start_dt = moment.unix(trip.start_dt).toDate();
          trip.end_dt = moment.unix(trip.end_dt).toDate();
          trips[trip.id] = trip;
        }
      return {
        ...state,
        trips: {
          ...state.trips,
          ...trips,
        },
        loading: false,
      };
    case "TRIP_UPDATE_SUCCESSFUL":
    case "TRIP_FETCH_SUCCESSFUL":
      const trip = action.response;
      trip.start_dt = moment.unix(trip.start_dt).toDate();
      trip.end_dt = moment.unix(trip.end_dt).toDate();

      const viewableTimezone = { ...state.viewableTimezone };
      if (!state.viewableTimezone[trip.id]) {
        viewableTimezone[trip.id] = moment.tz.guess();
      }

      return { ...state, trips: { ...state.trips, [trip.id]: trip }, viewableTimezone: viewableTimezone, loading: false, isSaving: false };
    case "TRIPS_FETCH_FAILED":
    case "TRIP_FETCH_FAILED":
    case "TRIP_UPDATE_FAILED":
      return { ...state, loading: false, isSaving: false, isError: true };
    case "TRIP_TIMEZONE_VIEW_SUCCESS":
      const tripId = action.response.tripId;
      const timezone = action.response.timezone;
      return { ...state, viewableTimezone: { ...state.viewableTimezone, [tripId]: timezone } };
    default:
      return state;
  }
};

export default tripReducer;
