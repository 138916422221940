import Api from "api/api";

export default class TripsApi extends Api {
  constructor() {
    super();
  }

  async getTrips(page = 1) {
    return this.axios.get(`/trips/?page=${page}`).then((response) => {
      return response.data;
    });
  }

  async getTrip(tripId, pub = false) {
    return this.axios.get(`/trips/${pub ? "public/" : ""}${tripId}`).then((response) => {
      return response.data;
    });
  }

  async createTrip(title, location, start_dt, end_dt, timezones) {
    return this.axios
      .post(`/trips/`, {
        title,
        location,
        start_dt,
        end_dt,
        timezones,
      })
      .then((response) => {
        return response.data;
      });
  }

  async updateTrip(tripId, title, location, start_dt, end_dt, timezones, shareable) {
    return this.axios
      .put(`/trips/${tripId}`, {
        title,
        location,
        start_dt,
        end_dt,
        timezones,
        shareable,
      })
      .then((response) => {
        return response.data;
      });
  }

  async setLocation(tripId, dateId, location) {
    return this.axios
      .post(`/trips/${tripId}/locations`, {
        tripId,
        dateId,
        location,
      })
      .then((response) => {
        return response.data;
      });
  }

  async deleteTrip(tripId) {
    return this.axios.delete(`/trips/${tripId}`, {}).then((response) => {
      return response.data;
    });
  }

  async getPaymentIntent(tripId, body = {}) {
    return this.axios.post(`/trips/${tripId}/payment-intent`, body).then((response) => {
      return response.data;
    });
  }

  async triggerExport(tripId, type = "ALL") {
    return this.axios.post(`/trips/${tripId}/export`, { type }).then((response) => {
      return response.data;
    });
  }
}
