// Reducer
import budgetReducer from "reducers/budgetReducer";
import checklistReducer from "reducers/checklistReducer";
import eventReducer from "reducers/eventReducer";
import tripReducer from "reducers/tripReducer";
import userReducer from "reducers/userReducer";
import { combineReducers } from "redux";

import noteReducer from "./noteReducer";

export default combineReducers({
  tripReducer,
  eventReducer,
  userReducer,
  checklistReducer,
  budgetReducer,
  noteReducer,
});
