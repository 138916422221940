import Api from "api/api";

export default class UserApi extends Api {
  constructor() {
    super();
  }

  async getMe() {
    return this.axios.get(`/users/me`).then((response) => {
      return response.data;
    });
  }

  async invite(email, trip) {
    return this.axios
      .post(`/users/invite`, {
        email,
        trip,
      })
      .then((response) => {
        return response.data;
      });
  }

  async updateUser(given_name, family_name) {
    return this.axios
      .put(`/users/me`, {
        given_name,
        family_name,
      })
      .then((response) => {
        return response.data;
      });
  }
}
