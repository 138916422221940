import Api from "api/api";

export default class ChecklistsApi extends Api {
  constructor() {
    super();
  }

  async getChecklists(tripId) {
    return this.axios.get(`/checklists/?tripId=${tripId}`).then((response) => {
      return response.data;
    });
  }

  async getChecklist(checklistId) {
    return this.axios.get(`/checklists/${checklistId}`).then((response) => {
      return response.data;
    });
  }

  async createChecklist(tripId, title, description) {
    return this.axios
      .post(`/checklists/`, {
        trip_id: tripId,
        title,
        description,
      })
      .then((response) => {
        return response.data;
      });
  }

  async updateChecklist(tripId, checklistId, items, title, description) {
    return this.axios
      .put(`/checklists/${checklistId}`, {
        trip_id: tripId,
        checklist_id: checklistId,
        items,
        title,
        description,
      })
      .then((response) => {
        return response.data;
      });
  }
}
