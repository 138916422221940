import "css/index.css";

import React from "react";

import { ENV } from "constants/items";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import { RecoilRoot } from "recoil";
import rootReducer from "reducers/index";
import { applyMiddleware, createStore } from "redux";
import { logger } from "redux-logger";
import createSagaMiddleware from "redux-saga";
import rootSaga from "sagas/index";
import * as serviceWorkerRegistration from "serviceWorkerRegistration";

import { ChakraProvider, createStandaloneToast } from "@chakra-ui/react";
import { CaptureConsole as CaptureConsoleIntegration } from "@sentry/integrations";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

import App from "./App";
import reportWebVitals from "./reportWebVitals";

const sentryEnvs = {
  development: "dev",
  production: "prod",
  staging: "staging",
};

Sentry.init({
  dsn: "https://78ce8c85718a41ba829330664b35afe6@o1198545.ingest.sentry.io/6321098",
  integrations: [
    new Integrations.BrowserTracing(),
    new Sentry.GlobalHandlers({ onerror: true, onunhandledrejection: true }),
    new CaptureConsoleIntegration({
      levels: ["error"],
    }),
  ],
  environment: sentryEnvs[ENV.REACT_APP_ENV],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
  beforeBreadcrumb: (breadcrumb, hint) => {
    if (breadcrumb.category === "xhr") {
      const data = {
        requestBody: hint.xhr.__sentry_xhr__.body,
        requestMethod: hint.xhr.__sentry_xhr__.method,
        requestUrl: hint.xhr.__sentry_xhr__.url,
        response: hint.xhr.response,
      };
      return { ...breadcrumb, data };
    }
    return breadcrumb;
  },
});

const sagaMiddleware = createSagaMiddleware();
const middlewares = [sagaMiddleware];

if (process.env.NODE_ENV === `development`) {
  middlewares.push(logger);
}

const globalStore = createStore(rootReducer, applyMiddleware(...middlewares));
sagaMiddleware.run(rootSaga);

const container = document.getElementById("root");
const root = createRoot(container);

const { ToastContainer } = createStandaloneToast();

// I'm weirded out by having to depend on google maps to load for the entire app to load...
root.render(
  // <React.StrictMode>
  <Router>
    <RecoilRoot>
      <Provider store={globalStore}>
        <ChakraProvider>
          <App />
          <ToastContainer />
        </ChakraProvider>
      </Provider>
    </RecoilRoot>
  </Router>
  // </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
if (ENV.REACT_APP_ENV !== "production") reportWebVitals(console.log);
