import UserApi from "api/services/user";
import { put, takeLatest } from "redux-saga/effects";

import { createStandaloneToast } from "@chakra-ui/react";
import * as Sentry from "@sentry/react";

const api = new UserApi();
const { toast } = createStandaloneToast();

function* hydrateUser(action) {
  try {
    const user = yield api.getMe();
    yield put({
      type: "USER_HYDRATE_SUCCESSFUL",
      response: {
        user,
      },
    });
  } catch (e) {
    Sentry.captureException(e);
    yield put({
      type: "USER_HYDRATE_FAILED",
      response: {
        error: e.message,
      },
    });
  }
}

function* updateUser(action) {
  try {
    const user = yield api.updateUser(action.payload.given_name, action.payload.family_name);
    toast({
      title: "Profile saved.",
      description: "We've saved your updated profile",
      status: "success",
      duration: 5000,
      isClosable: true,
    });
    yield put({
      type: "USER_UPDATE_SUCCESSFUL",
      response: {
        user,
      },
    });
  } catch (e) {
    Sentry.captureException(e);
    toast({
      title: "Error.",
      description: "There was an error updating your profile",
      status: "error",
      duration: 5000,
      isClosable: true,
    });
    yield put({
      type: "USER_UPDATE_FAILED",
      response: {
        error: e.message,
      },
    });
  }
}

function* mySaga() {
  yield takeLatest("USER_HYDRATE_REQUESTED", hydrateUser);
  yield takeLatest("USER_UPDATE_REQUESTED", updateUser);
}

export default mySaga;
