import axios from "axios";
import { COGNITO } from "constants/items";

import Auth from "@aws-amplify/auth";
import Amplify from "@aws-amplify/core";
import * as Sentry from "@sentry/react";

Amplify.configure({
  Auth: COGNITO,
});

export default class Api {
  constructor() {
    this.baseUrl = process.env.REACT_APP_API_BASE;

    this.axios = axios.create({
      baseURL: process.env.REACT_APP_API_BASE,
      timeout: 30000,
      headers: {
        common: {
          "Content-Type": "application/json",
        },
      },
    });

    this.axios.interceptors.request.use(
      (config) => this.getUserToken(config),
      (error) => {
        return Promise.reject(error);
      }
    );

    this.axios.interceptors.response.use(
      (response) => response,
      (error) => {
        // if (error.response) {
        //   // The request was made and the server responded with a status code
        //   // that falls out of the range of 2xx
        //   console.error(error.response.data);
        // } else if (error.request) {
        //   // The request was made but no response was received
        //   // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        //   // http.ClientRequest in node.js
        //   console.error(error.request);
        // } else {
        //   // Something happened in setting up the request that triggered an Error
        //   console.error(error.message);
        // }

        // Request unauthorized
        if (error.response && error.response.status === 401) {
          window.location.href = "/login";
          return {};
        }
        console.error("AxiosError", { message: error.message, config: error.config, response: error.response });
        Sentry.captureException(error);
        return Promise.reject(error);
      }
    );
  }

  async getUserToken(config) {
    try {
      const session = await Auth.currentSession();
      config.headers["Authorization"] = `Bearer ${session.getIdToken().getJwtToken()}`;
    } catch (e) {
      console.log("Sentry.captureException::getUserToken", e);
      // Sentry.captureException(e);
    }
    return config;
  }
}
