import TripsApi from "api/services/trips";
import { put, takeLatest } from "redux-saga/effects";

import { createStandaloneToast } from "@chakra-ui/react";
import * as Sentry from "@sentry/react";

// worker Saga: will be fired on USER_FETCH_REQUESTED actions
const api = new TripsApi();
const { toast } = createStandaloneToast();

function* fetchTrips(action) {
  try {
    let page = action.payload.page || 1;
    let total_pages = 1;

    while (page <= total_pages) {
      const json = yield api.getTrips(page);
      yield put({ type: "TRIPS_FETCH_SUCCESSFUL", response: json });
      page++;
      total_pages = json?.pagination?.count || 1;
      if (page > 100) break; //fail safe, "hard limit of 2500 events"
    }
  } catch (e) {
    Sentry.captureException(e);
    yield put({ type: "TRIPS_FETCH_FAILED", message: e.message });
  }
}

function* fetchTrip(action) {
  try {
    let pub = action.payload.pub || false;
    const json = yield api.getTrip(action.payload.tripId, pub);
    yield put({ type: "TRIP_FETCH_SUCCESSFUL", response: json });
  } catch (e) {
    Sentry.captureException(e);
    yield put({ type: "TRIP_FETCH_FAILED", message: e.message });
  }
}

function* setTimezone(action) {
  yield put({
    type: "TRIP_TIMEZONE_VIEW_SUCCESS",
    response: {
      tripId: action.payload.tripId,
      timezone: action.payload.timezone,
    },
  });
}

function* updateTrip(action) {
  try {
    const json = yield api.updateTrip(...Object.values(action.payload));
    toast({
      title: "Trip Updated.",
      description: "We've saved the changes to your trip",
      status: "success",
      duration: 5000,
      isClosable: true,
    });
    yield put({ type: "TRIP_UPDATE_SUCCESSFUL", response: json });
  } catch (e) {
    Sentry.captureException(e);
    toast({
      title: "Error.",
      description: "There was an error updating trip",
      status: "error",
      duration: 5000,
      isClosable: true,
    });
    yield put({ type: "TRIP_UPDATE_FAILED", message: e.message });
  }
}

function* mySaga() {
  yield takeLatest("TRIPS_FETCH_REQUESTED", fetchTrips);
  yield takeLatest("TRIP_FETCH_REQUESTED", fetchTrip);
  yield takeLatest("TRIP_TIMEZONE_VIEW_REQUESTED", setTimezone);
  yield takeLatest("TRIP_UPDATE_REQUESTED", updateTrip);
}

export default mySaga;
